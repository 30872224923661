import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { useDispatch, useSelector } from "react-redux";
import { Loader, SelectGroup } from "../../../../../../common/components";
import { viewClientDetailsExpenses } from "../../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { useParams } from "react-router-dom";
import { getSingleClientProfile } from "../../../../../../../features/Clients/clientSlice";
import { addMonths, format, parse, isValid } from "date-fns";

const Role = [
  { value: "6", label: "Last 30 days" },
  { value: "1", label: "Last Month" },
  { value: "3", label: "Last 6 Months" },
  { value: "5", label: "Last Quarter" },
  { value: "2", label: "Last 3 Months" },
  { value: "7", label: "Last Year" },
  { value: "4", label: "Last 12 Months" },
];

function ClientDetailExpenseChart() {
  const dispatch = useDispatch();
  const [selectedPeriodId, setSelectedPeriodId] = useState(Role[0].value);
  const { id } = useParams();
  const [clientDetails, setClientDetails] = useState({});
  const businessID = clientDetails?.businessDetail?.id;

  useEffect(() => {
    dispatch(
      getSingleClientProfile({
        profileId: id,
        successCallBack: onGetSingleClientProfileSuccess,
      })
    );
  }, [dispatch, id]);

  const onGetSingleClientProfileSuccess = (response) => {
    setClientDetails(response);
  };

  const businessId = businessID;

  useEffect(() => {
    if (businessId) {
      const payload = {
        periodId: +selectedPeriodId,
        businessDetailId: businessId,
      };
      dispatch(viewClientDetailsExpenses({ payload }));
    }
  }, [businessId, selectedPeriodId, dispatch]);

  const { data: viewClientDetail, isLoading: cardListLoading } = useSelector(
    (state) => state.dashboard.viewClientDetailsExpenses
  );

  const clientDetail =
    viewClientDetail && viewClientDetail.length > 0 ? viewClientDetail[0] : {};
  let labels =
    clientDetail?.expenseForClientOnTimeBasis?.map((item) =>
      format(parse(item.supplyDate, "yyyy-MM-dd", new Date()), "MMMM yyyy")
    ) || [];
  let expensesData =
    clientDetail?.expenseForClientOnTimeBasis?.map(
      (item) => item.monthlyExpense
    ) || [];

  // Check if there's only one month of data
  if (labels.length === 1 && expensesData.length === 1) {
    try {
      const singleMonth = parse(labels[0], "MMMM yyyy", new Date());
      if (isValid(singleMonth)) {
        const previousMonth = format(addMonths(singleMonth, -1), "MMMM yyyy");
        labels = [previousMonth, ...labels];
        expensesData = [0, ...expensesData];
      } else {
        console.error("Invalid date format:", labels[0]);
      }
    } catch (error) {
      console.error("Error parsing date:", error);
    }
  }

  const data = {
    labels: labels.length > 0 ? labels : ["No Data"],
    datasets: [
      {
        label: "Monthly Expenses",
        data: expensesData.length > 0 ? expensesData : [0],
        fill: false,
        borderColor: "#2E90FA",
        tension: 0.1,
        pointRadius: 2, // Dot size on the line
        pointHoverRadius: 6, // Dot size on hover
      },
    ],
  };

  const options = {
    scales: {
      x: { grid: { display: false } },
      y: {
        beginAtZero: true,
        grid: { borderDash: [5, 5] },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            const expense = context.raw;
            return `${expense} SAR`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  if (cardListLoading) {
    return (
      <div className="items-center flex px-40 py-12">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center space-x-4">
        <span className="flex items-center flex-grow text-text-primary font-semibold text-xl">
          Expense{" "}
        </span>
        <SelectGroup
          htmlFor="role"
          inputName="role"
          width="w-fit"
          options={Role}
          textColor="text-text-primary"
          className="!py-1"
          value={selectedPeriodId}
          onChange={(e) => setSelectedPeriodId(e.target.value)}
        />
      </div>

      <p className="text-sm font-normal mt-2 text-text-primary">
        {" "}
        Total Expense{" "}
      </p>
      <p className="text-1xl font-medium">
        {clientDetail?.totalExpense ?? "0"} SAR
      </p>

      <div className="flex justify-center mt-4">
        <div className="w-full" style={{ height: "300px" }}>
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default ClientDetailExpenseChart;
